import Vue from "vue";
import {gsap} from "gsap";
import {Expo, Power0} from 'gsap/all';
import {ScrollTrigger} from "gsap/ScrollTrigger";
import "bootstrap/dist/css/bootstrap.css";
import 'locomotive-scroll/dist/locomotive-scroll.css';
import 'flickity/dist/flickity.css';
import "./index.scss";
import Brands from './components/Brands';
import LocomotiveScroll from 'locomotive-scroll';
import Flickity from 'flickity';
import LazyLoad from "vanilla-lazyload";
import axios from 'axios';


gsap.registerPlugin(ScrollTrigger);
window.gsap = gsap;


window.app = new Vue({
    el: '#app',
    data: {
      contact: {csrf_token: window.__csrf__},
        loading: false,
        status: ''

    },
    components: {
        'brands': Brands
    },

    // workaround Jinja conflict with vue
    delimiters: ['${', '}'],
    created() {
        console.log('created');
    },
    methods : {
        processRequest(){
            this.loading = true;
            this.status = ':: Analyzing your Email ::'
            axios.post('/x/process',this.contact).then(res=>{
                this.status = "Thank you, we will be in touch shortly."
            }).catch(err=>{
                this.status = "Error sending Email, please double check your input or email us directly at hello[at]level09.com"
            }).finally(()=>{

                this.loading = false;

            })
        }

    },

    mounted() {
        let lazyLoad = new LazyLoad();
        const container = document.querySelector('[data-scroll-container]');
        const locoScroll = new LocomotiveScroll({

            el: container,
            smooth: true
        });

        locoScroll.on("scroll", ScrollTrigger.update);

        ScrollTrigger.scrollerProxy(container, {
            scrollTop(value) {
                return arguments.length ? locoScroll.scrollTo(value, 0, 0) : locoScroll.scroll.instance.scroll.y;
            },
            getBoundingClientRect() {
                return {top: 0, left: 0, width: window.innerWidth, height: window.innerHeight};
            },
            pinType: container.style.transform ? "transform" : "fixed"
        });


        let tl = gsap.timeline();
        gsap.set('.scroll', {autoAlpha: 0});
        tl.to('.preloader img',{opacity: 0, y:-20})
        tl.to('.preloader', {height: 0, ease: Expo.easeInOut});
        tl.fromTo('.navbar', {y: '-100%'}, {y: 0, ease: Expo.easeInOut});
        tl.fromTo('.hero > *', {opacity: 0, scale: 1.2, y: 100}, {
            y: 0,
            opacity: 1,
            scale: 1,
            ease: Expo.easeInOut,
            stagger: 0.1
        });
        let stl = gsap.timeline({repeat: -1});


        stl.from('.scroll', {y: 2, autoAlpha: 1, duration: 2});

        let atl = gsap.timeline({
            scrollTrigger: {
                trigger: '.about',
                scroller: container,
                start: 'top center',
                //markers: 1,
            }
        });
        atl.from('.about-txt > *',{opacity : 0, y: 50, duration: 1.2, ease: Expo.easeOut,
        stagger: 0.1});


        let btl = gsap.timeline({
            scrollTrigger: {
                trigger: '.brands',
                scroller: container,
                start: 'top 80%',
                // markers: 1,

            }
        });
        btl.from('.brands h3', {opacity: 0, y:20, duration: 1,ease: Expo.easeOut });

        btl.from('.brands img', {opacity: 0, scale: 1.5,
        stagger: 0.2} , '-0.2');




        // carousel
        const car = document.querySelector('#carousel');
        let flkty = new Flickity(car, {
                // options
                cellAlign: 'center',
                contain: true,
                wrapAround: true,
                autoplay: 2000,
                lazyLoad: true,
                pageDots: false,
            percentPosition: false,
            prevNextButtons: false

            });

        let ctl = gsap.timeline({
            scrollTrigger: {
                trigger: '.projects',
                scroller: container,
                start: 'top 87%',

            }

        });
        ctl.from('.carousel-cell img' , {width: 0, duration: 1,ease: Expo.easeOut, stagger: 0.12});



        ScrollTrigger.addEventListener("refresh", () => locoScroll.update());
        ScrollTrigger.refresh();

        let contl = gsap.timeline({
            scrollTrigger: {
                trigger: '.contact',
                scroller: container,
                start: 'top 67%'
            }
        });
        contl.from(
            '.contact-txt > *', {
                opacity: 0,
                y: 50,
                ease: Expo.easeOut
            }
        );




    }

});





